import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, ProgressBar, Button } from "react-bootstrap";
import { ErrorMessage, ToasterType, UploadedDoc, UploadPaylod } from "../../types";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import API from "../../service/api.service";
import { AppDispatch } from "../../redux/store";
import { showToaster } from "../../redux/reducers/shared.reducer";
import { base64ToBlob } from "../../service/common";

const validationSchema = Yup.object({
    file: Yup.mixed()
      .required("A file is required")
      .test("fileSize", "File size must be less than 10 MB", (value) => {
        if (!value || !(value instanceof File)) return false;
        return value.size <= 10 * 1024 * 1024; // 10 MB
      })
      .test("fileType", "Only PDF, JPEG, or PNG files are allowed", (value) => {
        if (!value || !(value instanceof File)) return false;
        return ["application/pdf", "image/jpeg", "image/png"].includes(value.type);
      }),
  });

interface FormData {
    file: File | null;
}

interface UploadDocumentProps {
    isActive: boolean;
    handleClose: (arg: boolean) => void,
    setDocument: (arg: UploadedDoc) => void;
    charge_id: string, 
    contract_id: number
}

const UploadDisputeDocument = ({ isActive, handleClose, setDocument, charge_id, contract_id  }: UploadDocumentProps) => {   
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>();
    const [inputKey, setInputKey] = useState(Date.now());

    const dispatch = useDispatch<AppDispatch>();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm<any>({
        resolver: yupResolver(validationSchema),
        defaultValues: { file: null },
      });
    
      const [uploadProgress, setUploadProgress] = useState<number>(0);
      const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
      const [uploadedFileSize, setUploadedFileSize] = useState<string>('');

      const uploadDocument = ( data:FormData ) => {
        setLoading(true);
        // Simulate file upload
        setUploadProgress(0);
        setUploadedFileName(data.file?.name || null);
        let displayFileSize = ''; 
        const fileSizeInKB = (data?.file?.size as number) / 1024;
        if (fileSizeInKB < 1024) { 
          displayFileSize = `${fileSizeInKB.toFixed(2)} KB`; } 
        else { 
          const fileSizeInMB = fileSizeInKB / 1024; 
          displayFileSize = `${fileSizeInMB.toFixed(2)} MB`;
        }

        setUploadedFileSize(displayFileSize);
        if (data.file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(data.file);
        } else {
          setPreviewImage(null);
        }

        setLoading(false);
      }

      const uploadWithProgress = async (payload: UploadPaylod) => {
        // Initialize progress to 0
        setUploadProgress(0);
      
        // Start interval to simulate progress
        const interval = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 100) {
              clearInterval(interval); // Clear interval when progress reaches 100%
            }
            return prev + 20; // Increment progress by 20
          });
        }, 500);
      
        try {
          // Await the response from the API call
          const response = await API({
            api: `support/upload_dispute_documents`,
            body: payload,
            type: 'FORM',
          });
      
          // Clear interval after the API call resolves successfully
          clearInterval(interval);
          setUploadProgress(100); // Set progress to 100 when complete
      
          return response; // Return the API response if needed
        } catch (error) {
          // Handle error, if any, and clear the interval
          clearInterval(interval);
          setUploadProgress(0); // Optionally reset progress on error
          console.error('Upload failed:', error);
          throw error;
        }
      };

      
      const onSubmit = async (data: FormData) => {
        // setLoading(true);

        if(!data.file){
          dispatch(showToaster({ message: "File is required!", type: ToasterType.Danger, status: true }));
          return false;
        }
        const document_file = await base64ToBlob(previewImage, data.file.type);
        if(!document_file) { return }

        const payload ={
          document_file,
          contract_id,
          charge_id
        }

        const response = await uploadWithProgress(payload);

        // setLoading(false);

        if (!response || (response.is_error && response.is_error === 1)) {
            dispatch(showToaster({...response, message: (response?.original_override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
        }
        else{
          setDocument({ file_name: uploadedFileName || '', doc_id: response.doc_id, doc_view_url: response.doc_view_url });
            // handleClose(false);
        }
      };

    const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      setValue("file", file, { shouldValidate: true });
    };

    const removeUpload = () => {
      setUploadedFileName(null);
      setValue("file", null, { shouldValidate: false });
      setInputKey(Date.now());
    }
    
    return (
      <Form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <Modal show={isActive} onHide={() => handleClose(false)} centered>
          <div className="p-4">
            <Modal.Header className="p-0 border-0 d-flex justify-content-between align-items-start mb-4">
              <h4 className="fs-18 fw-700 m-0 text-secondary">
                Upload Document
              </h4>
              <img
                src="../../assets/images/cross-circle.png"
                alt=""
                className="hw-30 pointer"
                onClick={() => handleClose(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0 mb-4">
              <Form.Group>
                <div
                  onDrop={onFileDrop}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    border: "2px dashed rgba(228, 228, 228, 1)",
                    borderRadius: "5px",
                    padding: "30px 20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <Controller
                    name="file"
                    control={control}
                    key={inputKey}
                    render={({ field }) => (
                      <>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .pdf"     
                          id="fileUpload"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            
                            if (e.target.files && e.target.files.length > 0) {
                              if (e.target.files[0]?.type.startsWith('image/') || e.target.files[0]?.type === 'application/pdf') {
                                field.onChange(e.target.files[0]);
                                setValue("file", e.target.files[0], {
                                  shouldValidate: false,
                                });
                                uploadDocument({
                                  file: e.target.files[0] || null,
                                });
                              }
                              else{
                                dispatch(showToaster({ message: "Supported files are pdf, jpg & png only!", type: ToasterType.Warning, status: true}))
                              }                              
                            }
                          }}
                        />
                        <label
                          htmlFor="fileUpload"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="../../assets/images/upload-icon.png"
                            alt=""
                          />
                          <p className="fs-14 fw-500 text-primary mb-0">
                            Click or Drag to Upload file
                          </p>
                          <span className="fs-12 fw-400 text-muted">
                            (Max file size: 10 MB, supported files: pdf, jpeg,
                            png)
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
                {errors.file && (errors.file.message as string)}
              </Form.Group>

              {uploadedFileName && (
                <div className="d-flex align-items-end mt-4">
                  <img
                    // src="../../assets/images/image-icon.png"                    
                    src={`../../assets/images/${uploadedFileName.includes('.pdf') ? 'pdf-icon' : 'image-icon'}.png`}
                    className="me-1"
                    alt=""
                  />
                  <span className="flex-fill">
                    <p className="m-0 fs-18 fw-600 text-secondary mb-1">
                      {uploadedFileName} •
                      <span className="fs12 text-muted">
                        {" "}
                        {uploadedFileSize}
                      </span>
                    </p>
                    <span
                      className="d-flex align-items-end bg-success w-100">
                      {uploadProgress > 0 && (
                        <ProgressBar
                          animated
                          now={uploadProgress}
                          style={{ height: "4px" }}
                          label={`${uploadProgress}%`}
                        />
                      )}
                    </span>
                  </span>
                  <p className="m-0">100%</p>
                  <img
                    src="../../assets/images/red-cross.png"
                    className="ms-2"
                    alt=""
                    onClick={removeUpload}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
            </Modal.Body>

            <Modal.Footer className="border-0 justify-content-start p-0">
              <Button
                variant="btn btn-secondary"
                type="submit"
                disabled={loading || !uploadedFileName}
                onClick={handleSubmit(onSubmit)}
              >
                Upload File
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </Form>
    );
};

export default UploadDisputeDocument;