import React from 'react';

interface NoDataAvailableProps {
  message?: string;
}

export const NoDataAvailable: React.FC<NoDataAvailableProps> = ({ message = "No data found, try reloading..." }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>   
      <h1 className="mt-3 text-center">{message}</h1>
    </div>
  );
};
