import { Row, Col, Button, Form, Overlay, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { AppDispatch } from "../../../../redux/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import API from "../../../../service/api.service";
import { showToaster } from "../../../../redux/reducers/shared.reducer";
import { ErrorMessage, RedirectFrom, ToasterType } from "../../../../types";
import { crypto, getPasswordStrength, getPasswordStrengthValue } from "../../../../service/common";
import { RootState } from "../../../../redux/reducers";
import { selectAuthDetails } from "../../../../redux/selectors";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { routes_constants } from "../../../../service/constants";
import "../my-profile.scss";
import "./change-password.scss";

interface ChangePasswordFormValues {
  current_password: string;
  password: string;
  confirm_password: string;
}

// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{6,12}$/;

// Define the Yup validation schema
const validationSchema = Yup.object().shape({
  current_password: Yup.string().required('Please enter current password'),
  password: Yup.string()
    .required('Please enter new password')
    .matches(/^\S*$/, "Password cannot contain spaces.")
    .min(6, 'Password must be at least 6 characters long.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(/\d/, 'Password must contain at least one number.')
    .matches(/[@$!%*?&]/, 'Password must contain at least one special character.'),
  confirm_password: Yup.string()
    .required('Please enter confirm password')
    .oneOf([Yup.ref('password'), ''], 'New Password and Confirm Password must be same.'),
});

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { patient_id } = useSelector((state: RootState) => selectAuthDetails(state));
  const [hideCurPwd, setHideCurPwd] = useState(false);
  const [hideNewPwd, setHideNewPwd] = useState(false);
  const [hideConfPwd, setHideConfPwd] = useState(false);
  const [strength, setStrength] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [hasUpperLowerCase, sethasUpperLowerCase] = useState(false)
  const [hasNumber, sethasNumber] = useState(false)
  const [hasSpecialChar, sethasSpecialChar] = useState(false)
  const [hasPassLength, sethasPassLength] = useState(false)
  // const [newPass, setNewPass] = useState('');
  const [barper, setBarPer] = useState(0);
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth });



  useEffect(() => {

    const handleResize = () => {

      setScreenSize({ width: window.innerWidth });
    };

    window.addEventListener('resize', handleResize);



    return () => window.removeEventListener('resize', handleResize);

  }, [screenSize]);

  const { register, handleSubmit, formState: { errors }, setValue, reset} = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      current_password: '',
      password: '',
      confirm_password: ''
    }
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await API({
      api: `auth/change_password`,
      body: { patient_id: `${patient_id}`, new_password: crypto(data.password), current_password: crypto(data.current_password) },
      type: 'POST'
    });
    setLoading(false);

    if (!response || (response.is_error && response.is_error === 1)) {
      dispatch(showToaster({message: response?.original_text || ErrorMessage.SOMETHING_WRONG, type: ToasterType.Danger, status: true}))
    }
    else{
      dispatch(showToaster({ message: "Password changed succesfully!", type: ToasterType.Success, status: true}))
      reset();
    }
    // reset();
    setBarPer(0);
    sethasPassLength(false);
    sethasSpecialChar(false);
    sethasNumber(false);
    sethasUpperLowerCase(false);
    setStrength('');
    // setValue('current_password', '');
    // setValue('password', '');
    // setValue('confirm_password', '');
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setShowOverlay(true)
    // setNewPass(newPassword)
    setValue("password", newPassword, { shouldValidate: true });
    if(/[A-Z]/.test(newPassword) && /[a-z]/.test(newPassword)){
      sethasUpperLowerCase(true);
    }else{
      sethasUpperLowerCase(false);
    }
    console.log('sds', (/[0-9]/.test(newPassword)))
    if(/[0-9]/.test(newPassword)){
      sethasNumber(true);
    }else{
      sethasNumber(false);
    }
    if(/[!@#$%^&*]/.test(newPassword)){
      sethasSpecialChar(true);
    }else{
      sethasSpecialChar(false);
    }
    if(newPassword.length >= 6){
      sethasPassLength(true);
    }else{
      sethasPassLength(false);
    }
    setStrength(getPasswordStrength(newPassword));
    setBarPer((getPasswordStrengthValue(newPassword)));
  };

  const handleFocus = () => {
    setShowOverlay(true);
  };

  const handleBlur = () => {
    setShowOverlay(false);
  };
  
  // const setbars = () => {
  //   return (
  //     <div className="d-flex flex-row mt-2">
  //       {[...Array(4)].map((_, i) => (
  //         <span
  //           key={i}
  //           className={`w-25 d-block  mx-1 ${((i < barper) && (barper === 1)) ? 'border-orange-custom' : (((i < barper) && (barper === 2)) ? 'border-orange-custom' : (((i < barper) && (barper === 3)) ? 'border-green-custom': (((i < barper) && (barper === 4)) ? 'border-green-custom' : 'border-grey-custom')))}`}
  //         ></span>
  //       ))}
  //     </div>
  //   );
  // };

  const target = useRef(null);
  
  return (
    <>
      <div className="px-5 mobile-padding mt-2 mxw-600">
        <h4 className="fs-20 fw-600 m-0 mb-2">Change Password</h4>
        <p className="fs-16 fw-400 m-0 mb-4">
          For better protection, avoid using a previously used password.
        </p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {/* Current Password */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label className="fs-14 fw-500 text-muted">
                  Current Password
                </Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={!hideCurPwd ? "password" : "text"}
                    {...register("current_password")}
                    isInvalid={!!errors.current_password}
                    placeholder="Enter your current password"
                    className="input_field"
                  />
                  {(
                    <img
                      src={
                        hideCurPwd
                          ? "../../assets/images/open-eye.svg"
                          : "../../assets/images/close-eye.svg"
                      }
                      alt=""
                      className="position-absolute eye-icon cursor"
                      onClick={() => setHideCurPwd(!hideCurPwd)}
                    />
                  )}
                </div>
                <Form.Text className="text-danger">
                  {errors.current_password?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            {/* New Password */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label className="fs-14 fw-500 text-muted">
                  New Password
                </Form.Label>
                <div className="position-relative">
                  <Form.Group controlId="password">
                    <Form.Control
                      type={!hideNewPwd ? "password" : "text"}
                      {...register("password")}
                      isInvalid={!!errors.password}
                      placeholder="Enter new password"
                      className="input_field"
                      onChange={handlePasswordChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      ref={target}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.password?.message}
                    </Form.Control.Feedback> */}
                    {(
                      <img
                        src={
                          hideNewPwd
                            ? "../../assets/images/open-eye.svg"
                            : "../../assets/images/close-eye.svg"
                        }
                        alt=""
                        className="position-absolute eye-icon cursor"
                        onClick={() => setHideNewPwd(!hideNewPwd)}
                      />
                    )}
                  </Form.Group>
                  <Overlay
                    target={target.current}
                    show={showOverlay}
                    placement={screenSize.width > 767 ? "right" : "bottom"}
                  >
                    {(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        <p className="text-left d-flex flex-column align-items-start m-0 py-3">
                          <p className={`fs-14 fw-700 text-nowrap m-0}`}>
                            {/* {!strength || strength === "Empty"
                              ? "Password Strength Indication"
                              : strength} */}
                              Password must contain
                          </p>
                          {/* <p className="w-100 mb-2">{setbars()}</p> */}
                          <p className={hasPassLength ? "fs-14 fw-500 text-nowrap text-grey-167 m-0 text-decoration-line-through" : "fs-14 fw-500 text-nowrap text-grey-24 m-0"}>
                            <img className={hasPassLength ? 'p-1 rounded-circle bg-blue me-2' : 'me-2 bg-white p-1 rounded-circle '} src={hasPassLength ? '../../assets/images/tick-blue.png' : '../../assets/images/blue-circle.png'} alt="" />
                            Atleast 6 characters
                          </p>
                          <p className={hasUpperLowerCase ? "fs-14 fw-500 text-nowrap text-grey-167 m-0 text-decoration-line-through" : "fs-14 fw-500 text-nowrap text-grey-24 m-0"}>
                          <img className={hasUpperLowerCase ? 'p-1 rounded-circle bg-blue me-2' : 'me-2 bg-white p-1 rounded-circle '} src={hasUpperLowerCase ? '../../assets/images/tick-blue.png' : '../../assets/images/blue-circle.png'} alt="" />
                          Upper and lower case
                          </p>
                          <p className={hasSpecialChar ? "fs-14 fw-500 text-nowrap text-grey-167 m-0 text-decoration-line-through" : "fs-14 fw-500 text-nowrap text-grey-24 m-0"}>
                          <img className={hasSpecialChar ? 'p-1 rounded-circle bg-blue me-2' : 'me-2 bg-white p-1 rounded-circle '} src={hasSpecialChar ? '../../assets/images/tick-blue.png' : '../../assets/images/blue-circle.png'} alt="" />
                          Add a symbol (e.g.!@#$%^&*)
                          </p>
                          <p className={hasNumber ? "fs-14 fw-500 text-nowrap text-grey-167 m-0 text-decoration-line-through" : "fs-14 fw-500 text-nowrap text-grey-24 m-0"}>
                          <img className={hasNumber ? 'p-1 rounded-circle bg-blue me-2' : 'me-2 bg-white p-1 rounded-circle '} src={hasNumber ? '../../assets/images/tick-blue.png' : '../../assets/images/blue-circle.png'} alt="" />
                          Include a number (0-9)
                          </p>
                        </p>
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
                {/* <Form.Text className="text-danger">
                {errors.password?.message}
              </Form.Text> */}
                {(errors.password?.message) && (
                  <small className="text-danger form-text">
                    {errors.password?.message}
                  </small>
                )}
              </Form.Group>
            </Col>

            {/* Confirm Password */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label className="fs-14 fw-500 text-muted">
                  Confirm Password
                </Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={!hideConfPwd ? "password" : "text"}
                    {...register("confirm_password")}
                    isInvalid={!!errors.confirm_password}
                    placeholder="Enter confirm password"
                    className="input_field"
                  />
                  {(
                    <img
                      src={
                        hideConfPwd
                          ? "../../assets/images/open-eye.svg"
                          : "../../assets/images/close-eye.svg"
                      }
                      alt=""
                      className="position-absolute eye-icon cursor"
                      onClick={() => setHideConfPwd(!hideConfPwd)}
                    />
                  )}
                </div>
                {errors.confirm_password?.message && (
                  <Form.Text className="text-danger">
                    {errors.confirm_password?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          {/* Buttons */}
          <div className="d-flex align-items-center">
            <Button
              variant="secondary"
              type="submit"
              className="mnw-125 py-2 me-3 fw-bold"
              disabled={loading}
            >
              {loading ? "Updating..." : "Save"}
            </Button>
            <Link
              to={routes_constants.dashboard}
              state={{ from: RedirectFrom.PROFILE_PAGE }}
            >
              <Button
                variant="outline-secondary"
                className="mnw-125 py-2 ms-2 fw-bold"
              >
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
};
