import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  ListGroup,
  ListGroupItem
} from "react-bootstrap";
import "./report-problem.scss";
import { Link } from "react-router-dom";
import { routes_constants } from "../../../service/constants";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadDisputeDocument from "../../ModalComponent/Upload.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { selectContractDetails } from "../../../redux/selectors/contracts.selector";
import { selectAuthDetails, selectSharedDetails } from "../../../redux/selectors";
import { TransactionType } from "../../../types/contract.type";
import API from "../../../service/api.service";
import { showToaster } from "../../../redux/reducers/shared.reducer";
import { ErrorMessage, PaymentTypeStatic, Reason, ToasterType, UploadedDoc } from "../../../types";
import { AppDispatch } from "../../../redux/store";
import { reasonsList } from "../../../redux/actions";
import { contractList } from "../../../redux/actions/contracts.action";
import moment from "moment";
import { getOtherPayments } from "../../../service/common";
import CustomMessage from "../../ModalComponent/CustomMessage";

interface FormData {
  contract_id: number;
  charge_id: string;
  payment_type_enum: number;
  patient_email: string;
  patient_mobile: number;
  doc_ids: string;
  reason_id: number;
  reason: string;
  termsAccepted: boolean;
}

const validationSchema = Yup.object().shape({
  contract_id: Yup.number()
  .transform((value, originalValue) => (originalValue.trim() === "" ? null : value))
  .nullable() // Allows null values to pass validation before the `required` check
  .required("Contract ID is required."),
  charge_id: Yup.string().required("Transaction is required."),
  payment_type_enum: Yup.string().required("Payment Type is required."),
  reason_id: Yup.number()
  .transform((value, originalValue) => (originalValue.trim() === "" ? null : value))
  .nullable() // Allows null values to pass validation before the `required` check
  .required("Reason is required."),
  patient_email: Yup.string().email("Invalid email format").required("Email is required."),
  patient_mobile: Yup.string()
  .matches(/^\d+$/, "Phone number must contain only digits")
  .min(10, "Phone number must be at least 10 digits")
  .max(15, "Phone number must not exceed 15 digits")
  .required("Phone number is required."),
  reason: Yup.string().max(500, "Message cannot exceed 500 characters."),
  termsAccepted: Yup.boolean()
    .oneOf([true], "You must accept the Terms & Conditions.")
    .required("Terms & Conditions must be accepted."),
});

// const StaticPaymentTypes = ['Recurring Payment', 'Partial payment', 'Social Healthcare', 'Upfront Payment', 'Down payment',  'Change Payment Date', 'Late Fees', 'Other'];

const ReportProblemComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  // const [customMessage, setCustomMessage] = useState(false);
  const [success_message, setSuccessMessage] = useState('');
  const [uploadDocComp, setUploadDocComp] = useState(false);
  const [charge_id, setChargeId] = useState<string>();
  // const [payment_type, setPaymentType] = useState<number | null>();
  const [contract_id, setContractId] = useState<number | null>();
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [payment_types, setPaymentTypes] = useState<PaymentTypeStatic[]>([]);
  const [uploadedDoc, setUploadedDoc] = useState<UploadedDoc[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => selectAuthDetails(state));
  const { contracts } = useSelector((state: RootState) => selectContractDetails(state));
  const { reasonList } = useSelector((state: RootState) => selectSharedDetails(state));

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    // if (!reasonList.length) {
      dispatch(reasonsList({ limit: 100, offset: 0, reason_type: 'dispute_reasons' }));
    // }

    if (!contracts.length) {
      dispatch(contractList({ data: {limit: 100, offset: 0} }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    const doc_ids = uploadedDoc.map(item => item.doc_id).join(',');
    data['charge_id'] = data.charge_id === `other_${contract_id}` ? "" : data.charge_id;
    
    const payload = { ...data, doc_ids, payment_type: payment_types[0]?.payment_type, patient_mobile_country_code : user?.patient_mobile_country_code || '+1' };
    const response = await API({
        api: `support/add_customer_dispute`,
        body: payload,
        type: 'POST'
    });

    setLoading(false);
    if (!response || (response.is_error && response.is_error === 1)) {
        dispatch(showToaster({ message: (response?.original_override_text || response?.original_text) || ErrorMessage.SOMETHING_WRONG, type: ToasterType.Danger, status: true}))
    }
    else if(response.ticket_id){
        const success_message = `Your Concern has been successfully submitted.
                Our customer support team will be contacting you soon.
                Your Case Number is ${response.ticket_id}. Please save it for future reference.`;
        setSuccessMessage(success_message);
        setChargeId('');
        setTransactions([]);
        setUploadedDoc([]);
        reset();
    }
  };

  const selectContractId = async (contract_id: number) => {
    setValue('payment_type_enum', "");
    setValue('charge_id', '');
    setPaymentTypes([]);
    setChargeId(''); setContractId(null);

    if(contract_id){
      setContractId(contract_id);
      setLoading(true);
      const response = await API({
        api: `details/get_paid_payments?contract_id=${contract_id}`,
        type: 'GET'
      });
      setLoading(false);

      if (!response || (response.is_error && response.is_error === 1)) {
        dispatch(showToaster({ message: ((response?.original_override_text || response?.original_text) || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true }));
      }

      else{
        let result = (response?.payments && response.payments.length) ? response.payments : [];
        setTransactions(result);  
      }
    }    
  }

  const selectPayment = (charge_id: string) => {
    

    if(charge_id === `other_${contract_id}`){
      setChargeId( `other_${contract_id}`);
      const static_payment = getOtherPayments();
      setTimeout(() => {
        setPaymentTypes(static_payment);
      }, 0);
      setValue('payment_type_enum', static_payment[0]?.pt_name);
  }
    else{
      setChargeId(charge_id);
      const type = transactions.find((t) => t.charge_id === charge_id);
      if(type?.payment_type_enum && type?.payment_type){
        // console.log(type);
        setPaymentTypes([]);
        setTimeout(() => {
          setPaymentTypes([{ pt_name: type.payment_type_enum, payment_type: type.payment_type }]);
        }, 0);
        setValue('payment_type_enum', type.payment_type_enum);
      }
    }
  }

  const setDocument = (doc: UploadedDoc) => {
    setUploadDocComp(false);
    setUploadedDoc(uploadedDoc.concat(doc));
  }

  const removeUpload = async (doc_id: number) => {    
    setLoading(true);
    const response = await API({
      api: `support/remove_dispute_documents`,
      body: { doc_id },
      type: 'POST'
    });
    setLoading(false);

    if(!response){ dispatch(showToaster({ message: ErrorMessage.SOMETHING_WRONG, type: ToasterType.Success, status: true })); return false; }

    if(response.flag){
      if (response.is_error && response.is_error === 1) {
        dispatch(showToaster({ message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true }));
      }
      else{
        const newDocs = uploadedDoc.filter(doc => doc.doc_id !== doc_id);
        setUploadedDoc(newDocs);
        setContractId(contract_id);
      }
    }
    else{
      dispatch(showToaster({ message: "Unable to delete, please try again!", type: ToasterType.Warning, status: true }));

    }
  }

  return (
    <>
      {uploadDocComp && charge_id && contract_id && (
        <UploadDisputeDocument
          setDocument={setDocument}
          isActive={uploadDocComp}
          handleClose={() => setUploadDocComp(false)}
          charge_id={charge_id}
          contract_id={contract_id}
        />
      )}
      {success_message && (
        <CustomMessage isActive={success_message ? true : false} handleClose={() => setSuccessMessage('')} title="Concern submitted" message={success_message}/>
      )}

      <div className="support-wrapper vh-100 overflow-hidden">
        <div className="support-header hw-65 w-100 d-flex align-items-center justify-content-between border-bottom px-4 py-2">
          <img src="../../assets/images/FM-logo.svg" alt="" />
        {/* TODO: Notification below pending for phase-2 */}
          {/* <img src="../../assets/images/bell-icon.png" alt="" /> */}
        </div>
        <div className="support-content w-100  h-100 p-3 d-flex">
          <Link to={routes_constants.support} className="decoration-none">
            <img
              src="../../assets/images/back-circle.png"
              className="hw-40 rounded-circle me-3"
              alt=""
            />
          </Link>
          <Link to={routes_constants.support} className="decoration-none">
          <p className="fs-16 fw-700 m-0 text-secondary">Back to help</p>
          </Link>

          <div className="support-inner h-100 overflow-auto hide-scroll-bar flex-fill">
            <Row className="container-xl h-100 mx-auto pt-5">
              <Col sm={4} className="px-5">
                <img
                  src="../../assets/images/warning-page.svg"
                  alt=""
                  className="w-100"
                />
              </Col>
              <Col>
                {/* {success_message ? (
                  <p>{success_message}</p>
                ) : ( */}
                  <div className="w-75">
                    <div className="w-100 mb-5">
                      <h4 className="fs-24 fw-500 m-0 mb-2">
                        Report an issue with contract
                      </h4>
                      <p className="fs-16 fw-400 m-0 text-muted">
                        Send us a message, and we'll respond as soon as
                        possible.
                      </p>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label className="fs-14 fw-400 text-muted m-0">
                              Select Contract ID
                            </Form.Label>
                            <Form.Select
                              className="input_field"
                              {...register("contract_id")}
                              onChange={(e) =>
                                selectContractId(parseInt(e.target?.value))
                              }
                            >
                              <option value="">-- Select --</option>
                              {contracts?.length ?
                                contracts.map((contract: any) => (
                                  <option
                                    value={contract.contract_id}
                                    key={contract?.contract_id}
                                  >
                                    {contract?.contract_code}
                                  </option>
                                )) : (
                                  <option value="">--No contract--</option>
                                )}
                            </Form.Select>
                            <Form.Text className="text-danger">
                              {errors.contract_id?.message as string}
                            </Form.Text>
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label className="fs-14 fw-400 text-muted m-0">
                              Select Transaction
                            </Form.Label>
                            <Form.Select
                              className="input_field"
                              {...register("charge_id")}
                              onChange={(e) => selectPayment(e.target.value)}
                              disabled={!transactions.length || !contract_id}
                            >
                              <option value="">
                                -- {loading ? "Loading" : "Select"} --
                              </option>
                              {transactions &&
                                transactions.map(
                                  (transaction: TransactionType) => (
                                    <option
                                      value={transaction.charge_id}
                                      key={transaction?.charge_id}
                                    >
                                      ${transaction?.amount_deducted} on {moment(transaction?.last_updated).format('MM/DD/YYYY')}
                                    </option>
                                  )
                               )}
                               <option
                                  value={`other_${contract_id}`}
                                  key="others"
                                >
                                  Others
                                </option>
                            </Form.Select>
                            <Form.Text className="text-danger">
                              {errors.charge_id?.message as string}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>

                      {charge_id && (
                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label className="fs-14 fw-400 text-muted m-0">
                                Payment Type
                              </Form.Label>
                              {/* <Form.Control
                                className="input_field"
                                type="text"
                                placeholder="Payment type"
                                {...register("payment_type_enum")}
                                disabled={!charge_id}
                              /> */}
                              <Form.Select
                              className="input_field"
                              {...register("payment_type_enum")}
                              disabled={charge_id !==  `other_${contract_id}`}
                                >
                                  {payment_types?.length &&
                                    payment_types.map(
                                      (payment_type: PaymentTypeStatic) => (
                                        <option
                                          value={payment_type.pt_name}
                                          key={payment_type.payment_type}
                                        >
                                          {payment_type.pt_name}
                                        </option>
                                      )
                                  )}
                                </Form.Select>
                              <Form.Text className="text-danger">
                                {errors.payment_type_enum?.message as string}
                              </Form.Text>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label className="fs-14 fw-400 text-muted m-0">
                                Select Reason
                              </Form.Label>
                              <Form.Select
                                className="input_field"
                                {...register("reason_id")}
                              >
                                <option value="">-- Select --</option>
                                {reasonList?.length &&
                                  reasonList.map((reason: Reason) => (
                                    <option value={reason.id} key={reason?.id}>
                                      {reason?.reason}
                                    </option>
                                  ))}
                              </Form.Select>
                              <Form.Text className="text-danger">
                                {errors.reason_id?.message as string}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      <Form.Group className="mb-3">
                        <Form.Label className="fs-14 fw-400 text-muted m-0">
                          Email
                        </Form.Label>
                        <Form.Control
                          className="input_field"
                          type="email"
                          defaultValue={user?.patient_email}
                          placeholder="Enter email"
                          {...register("patient_email")}
                        />
                        <Form.Text className="text-danger">
                          {errors.patient_email?.message as string}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label className="fs-14 fw-400 text-muted m-0">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          className="input_field"
                          type="Number"
                          defaultValue={user?.patient_mobile}
                          placeholder="Enter phone number"
                          {...register("patient_mobile")}
                        />
                        <Form.Text className="text-danger">
                          {errors.patient_mobile?.message as string}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <div className="d-flex align-items-start justify-content-between">
                        {charge_id && contract_id && (
                          <>
                          <div>
                            <Form.Label className="fs-18 fw-600 m-0 text-grey">
                              Upload Supporting Documents
                            </Form.Label>
                            <br />
                            <Form.Text className="fs-14 fw-400 text-muted m-0">
                              Please upload clear copy of document
                            </Form.Text>
                            <br />
                          </div>                          
                            <p
                              className="text-primary fs-16 fw-700 m-0 pointer"
                              onClick={() => setUploadDocComp(true)}
                            >
                              Upload
                            </p>
                            </>
                        )}
                        </div>
                        <ListGroup>
                          {uploadedDoc &&
                            uploadedDoc.map((doc: UploadedDoc) => (
                              <ListGroupItem className="d-flex align-items-center justify-content-between border-0 border-bottom rounded-0">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={`../../assets/images/${doc.file_name.includes('.pdf') ? 'pdf-icon' : 'image-icon'}.png`}
                                    alt="Close"
                                    className=""
                                  />
                                  <p className="m-0 ms-2 fs-14 fw-600 text-secondary">
                                    {doc.file_name}
                                  </p>
                                </span>
                                {loading ? (
                                  "Loading..."
                                ) : (
                                  <span className="d-flex align-items-center">
                                    <a href={doc.doc_view_url} target="_blank" rel="noreferrer">
                                    <img
                                      src="../../assets/images/blue-eye.png"
                                      alt="Close"
                                      className="pointer me-3"
                                    />
                                    </a>
                                    <img
                                      src="../../assets/images/red-cross.png"
                                      alt="Close"
                                      className="pointer"
                                      onClick={() => removeUpload(doc.doc_id)}
                                    />
                                  </span>
                                )}
                              </ListGroupItem>
                            ))}
                        </ListGroup>
                        <Form.Text className="text-danger">
                          {errors.doc_ids?.message as string}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="fs-14 fw-400 text-muted m-0">
                          Message
                        </Form.Label>
                        <Form.Control
                          className="input_field"
                          as="textarea"
                          rows={3}
                          {...register("reason")}
                          placeholder="Enter here..."
                        />
                        <Form.Text className="text-danger">
                          {errors.reason?.message as string}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="termsAccepted">
                        <Form.Check
                          className="fs-14"
                          type="checkbox"
                          label="By clicking this box, you agree to our Terms & Conditions."
                          {...register("termsAccepted")}
                        />
                        <Form.Text className="text-danger">
                          {(errors.termsAccepted?.message as string) || ""}
                        </Form.Text>
                      </Form.Group>

                      <Button
                        variant="secondary"
                        type="submit"
                        className="px-3"
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                {/* )} */}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportProblemComponent;
