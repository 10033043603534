import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomerNotification, DashboardTabType, defaultChangedDateInfo } from "../../../types";
import { AppDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducers";
import { toggleDrawer } from "../../../redux/reducers/notification.reducer";
import { selectNotificationDetails } from "../../../redux/selectors/notification.selector";
import { markAsRead } from "../../../redux/actions/notification.action";
import { selectAuthDetails } from "../../../redux/selectors";
import { useNavigate } from "react-router-dom";
import { contractDetails } from "../../../redux/actions/contracts.action";
import { switchDashboardTab } from "../../../redux/reducers/shared.reducer";
import { setChangedDateInfo } from "../../../redux/reducers/payment.reducer";

const NotificationDrawer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { notifications, isDrawerOpen } = useSelector((state: RootState) =>
    selectNotificationDetails(state)
  );

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) =>
    selectAuthDetails(state)
  );

  const drawerRef = useRef<HTMLDivElement>(null);

  const handleMarkAsRead = (notify: CustomerNotification) => {
    if(user?.patient_id){
      dispatch(markAsRead({ bn_id: notify.bn_id, patient_id: user?.patient_id }));
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
      dispatch(toggleDrawer()); // Close the drawer
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  const selectContract = (notify: CustomerNotification) => {
    if(notify?.metadata?.contract_id){
      const data = { id: notify?.metadata?.contract_id, navigate };
      dispatch(contractDetails({ data }))
      dispatch(switchDashboardTab(DashboardTabType.OVERVIEW));
      dispatch(setChangedDateInfo(defaultChangedDateInfo));  
    }
    handleMarkAsRead(notify);
    dispatch(toggleDrawer());
  }
    
  return (
    <div
      ref={drawerRef}
      className={`offcanvas offcanvas-end ${isDrawerOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        visibility: isDrawerOpen ? "visible" : "hidden",
        transition: "visibility 0.3s ease, transform 0.3s ease",
        transform: isDrawerOpen ? "translateX(0)" : "translateX(100%)",
      }}
      role="dialog"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Notifications</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => dispatch(toggleDrawer())}
        ></button>
      </div>
      <div className="offcanvas-body">
        {notifications.length > 0 ? (
          <ul className="list-group">
            {notifications.map((notify: CustomerNotification) => (
              <li
                key={notify.bn_id}
                className={`list-group-item d-flex justify-content-between align-items-center ${
                  notify.read_at ? "text-muted" : ""
                }`}
                onClick={() => selectContract(notify)}
              >
                <span>{notify.message}</span>
                {/* {!notify.read_at && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleMarkAsRead(notify.bn_id)}
                  >
                    Mark as Read
                  </button>
                )} */}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-muted">No notifications</p>
        )}
      </div>
    </div>
  );
};

export default NotificationDrawer;
