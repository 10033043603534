import React from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { MyProfile } from './my-profile/my-profile.component';
import './profile-layout.scss';
import { ChangePassword } from './my-profile/change-password/change-password';
import { Link } from 'react-router-dom';
import { routes_constants } from '../../service/constants';
import { RedirectFrom } from '../../types';
import NotificationIcon from '../common/notification/notification';


export const Profile: React.FC = () => {
  return (
    <>
      <div className="profile-header hw-65 w-100 d-flex align-items-center justify-content-between border-bottom px-4 py-2">
        <img src="../../assets/images/FM-logo.svg" alt="" />
        {/* <img src="../../assets/images/bell-icon.png" alt="" /> */}
        <NotificationIcon />
      </div>
      <div className="bg-white p-4 vh-100 overflow-hidden mobile-pad profile-wrapper">
        <div className="d-flex align-items-center">
          <Link to={routes_constants.dashboard} state={{ from: RedirectFrom.PROFILE_PAGE }} className="decoration-none">
          <img
            src="../../assets/images/back-circle.png"
            className="hw-40 rounded-circle me-3"
            alt=""
          />
          </Link>
          <Link to={routes_constants.dashboard} state={{ from: RedirectFrom.PROFILE_PAGE }} className="decoration-none">
          <p className="fs-16 fw-700 m-0 text-secondary">Back to dashboard</p>
          </Link>
        </div>
        <Container className="h-100 profile-wrapper-inner">
          <Tab.Container defaultActiveKey="profile">
            <Row className="h-100 overflow-hidden">
              <Col md={3} className="h-100 mobile-format overflow-auto">
                <Nav variant="pills" className="flex-lg-column">
                  <Nav.Item>
                    <Nav.Link eventKey="profile" className="grey-106">
                      My Profile
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="payment" className="grey-106">
                      Payment Method
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="password" className="grey-106">
                      Change Password
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col md={9} className="h-100 overflow-auto hide-scroll-bar">
                <Tab.Content>
                <Tab.Pane eventKey="profile">
                    <MyProfile />
                  </Tab.Pane>
                  <Tab.Pane eventKey="password">
                    <ChangePassword />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </>
  );
};
