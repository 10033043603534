import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerNotification, StatusType } from "../../types";
import { customerNotifications, markAsRead, NotificationRes } from "../actions/notification.action";

interface NotificationState {
  notifications: CustomerNotification[];
  unread_count: number;
  count: number;
  isDrawerOpen: boolean;
  PATIENT_CONNECTED: boolean;
  status: StatusType
}

const initialState: NotificationState = {
  notifications: [],
  unread_count: 0,
  count: 0,
  isDrawerOpen: false,
  PATIENT_CONNECTED: false,
  status: StatusType.Idle
};

const notificationReducer = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    patientConnected: (state, action: PayloadAction<boolean>) => {
      state.PATIENT_CONNECTED = action.payload;
    },
    addNotification: (state, action: PayloadAction<CustomerNotification>) => {
      state.notifications.push(action.payload);
    },
    // markAsRead: (state, action: PayloadAction<string>) => {
      
    // },
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  }, 
  extraReducers: (builder) => {
    builder
    .addCase(markAsRead.pending, (state) => {
      state.status = StatusType.Loading;
    })
    .addCase(markAsRead.fulfilled, (state, action: PayloadAction<number>) => {
      const notification = state.notifications.find(
        (notif) => notif.bn_id === action.payload
      );
      if (notification) {
        notification.read_at = new Date();
      }
    })
    .addCase(markAsRead.rejected, (state, action) => {
      state.status = StatusType.Failed;
    })
    .addCase(customerNotifications.pending, (state) => {
      state.status = StatusType.Loading;
    })
    .addCase(customerNotifications.fulfilled, (state, action: PayloadAction<NotificationRes>) => {
      let notifications = action.payload?.data?.length ? action.payload.data : [];;
      notifications = notifications.map((notification) => ({...notification, metadata: JSON.parse(notification?.metadata)}));
      state.notifications = notifications;
      state.unread_count = action.payload?.unread_count || 0;
      state.count = action.payload?.count || 0;
    })
    .addCase(customerNotifications.rejected, (state) => {
      state.status = StatusType.Failed;
    })
  }  
});

export const { addNotification, toggleDrawer, patientConnected } = notificationReducer.actions;
export default notificationReducer.reducer;
