import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectNotificationDetails = createSelector(
  (state: RootState) => state.notification,
  (notification) => ({    
    notifications: notification.notifications,
    isDrawerOpen: notification.isDrawerOpen,
    PATIENT_CONNECTED: notification.PATIENT_CONNECTED
  })
);
