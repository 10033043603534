import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import Routing from './routing/Routing';
import ErrorBoundary from './components/common/error/Error.Boundry.component';
import { GoogleReCaptchaProvider } from '@google-recaptcha/react';
import { getGoogleCaptchaAPIKey } from './service/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleReCaptchaProvider type="v3" siteKey={getGoogleCaptchaAPIKey()}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          <Routing />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
    </GoogleReCaptchaProvider>
);
