import { io, Socket } from "socket.io-client";
import { defaultAppDefaults } from "./constants";

const SOCKET_URL = defaultAppDefaults.socket_url; // Backend server URL

let socket: Socket;

export const connectSocket = (): Socket => {
  if (!socket) {
    socket = io(SOCKET_URL, {
      path: '/api/socket.io', 
      transports: ['websocket']
    });
  }
  console.log(socket);
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};
