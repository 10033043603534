//will write some magic here

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AlternativeEmail, AlternativeMobile, FAQs, Pagination, Reason } from "../../types";
import API from "../../service/api.service";

export const sharedAction = {}

interface ReasonInput extends Pagination{
  reason_type?: string;
}

export const reasonsList = createAsyncThunk<Reason[], ReasonInput , { rejectValue: string }>(
    'support/reasons_list',
    async ({reason_type = 'callback_reasons'}, { rejectWithValue }) => {
      try {         
  
        const response = await API({
          api: `support/fetch_all_callback_reasons?limit=100&offset=0&reason_type=${reason_type}`,
          type: 'GET',
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }
  
        return response.data;      
      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
      }
    }
  );

  interface FaqListInput {
    country_id?: number;
    currency_id?: number;
    type: number;
  }

  export const faqsList = createAsyncThunk<FAQs[], FaqListInput , { rejectValue: string }>(
    'support/faq_list',
    async (input, { rejectWithValue }) => {
      try {         
  
        let queryParams = `country_id=${input?.country_id || 1}&type=${input.type}`
        queryParams = input.currency_id ? queryParams + `&currency_id=${input?.currency_id}` : queryParams;

        const response = await API({
          api: `support/get_faq?${queryParams}`,
          type: 'GET',
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }
  
        return response.result;
      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
      }
    }
  );

  export const alternativeEmailList = createAsyncThunk<AlternativeEmail[], void , { rejectValue: string }>(
    'shared/alternate_emails',
    async (_, { rejectWithValue }) => {
      try {         
  
        const response = await API({
          api: `profile/alternate_emails_list`,
          type: 'POST',
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }
  
        return response?.alternate_emails?.length ? response.alternate_emails : [];

      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to fetch Alternative Emails');
      }
    }
  );

  export const alternativeMobileList = createAsyncThunk<AlternativeMobile[], void , { rejectValue: string }>(
    'shared/alternate_mobiles',
    async (_, { rejectWithValue }) => {
      try {         
  
        const response = await API({
          api: `profile/alternate_mobiles_list`,
          type: 'POST',
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }
  
        return response?.alternate_mobiles?.length ? response.alternate_mobiles : [];

      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to fetch Alternative Mobile');
      }
    }
  );