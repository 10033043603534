import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomerNotification } from "../../../types";
import { AppDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducers";
import { toggleDrawer } from "../../../redux/reducers/notification.reducer";
import { selectNotificationDetails } from "../../../redux/selectors/notification.selector";
import NotificationDrawer from "./notification.drawer";

const NotificationIcon = () => {
  const dispatch = useDispatch<AppDispatch>();
//   const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const { notifications } = useSelector((state: RootState) =>
    selectNotificationDetails(state)
  );

  const unreadCount = notifications.filter((notif: CustomerNotification) => !notif.read_at).length;

  const handleIconClick = () => {
    dispatch(toggleDrawer());
  };

  return (
    <>
        <div style={{ position: "relative", cursor: "pointer" }} onClick={handleIconClick}>
        {/* <span style={{ fontSize: "24px" }}>🔔</span> */}
        
        {unreadCount > 0 && (
            <span
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "4px 8px",
                fontSize: "12px",
            }}
            >
            {unreadCount}
            </span>
        )} 
        <img src="../../assets/images/bell-icon.png" alt="" />     
        </div>
        <NotificationDrawer />
    </>
    
  );
};

export default NotificationIcon;
