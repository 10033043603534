import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthDetails, selectSharedDetails } from './redux/selectors';
import { RootState } from './redux/reducers';
import { NotificationToaster } from './components/common';
import { connectSocket, disconnectSocket } from './service/socket.service';
import { patientConnected } from './redux/reducers/notification.reducer';
import { AppDispatch } from './redux/store';
import { selectNotificationDetails } from './redux/selectors/notification.selector';
import { customerNotifications } from './redux/actions/notification.action';

const App: React.FC = () => {
  const { user } = useSelector((state: RootState) => selectAuthDetails(state)); 
  const { toaster } = useSelector((state: RootState) => selectSharedDetails(state)); 
  const { PATIENT_CONNECTED } = useSelector((state: RootState) => selectNotificationDetails(state));
  
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const socket = connectSocket();
    if(user?.patient_id){
      socket.on("connect", () => {
        if(!PATIENT_CONNECTED){
          console.log("Connected to server");
          socket.emit("PATIENT_CONNECTED", { patient_id: user?.patient_id });
          dispatch(patientConnected(true));  
        }        
      });
    }
    else{
      console.warn("User or patient_id is undefined. Skipping PATIENT_CONNECTED event.");
    }

    socket.on('new_notification', (data) => {
      // dispatch(addNotification(data));
      if(user?.patient_id){//Refress notification
        dispatch(customerNotifications({ limit: 100, offset: 0, patient_id: user.patient_id }));
      }
      // console.log('Received new notification:', data);
    });

    return () => {
      disconnectSocket();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);//NOTE: Do not add any dependancy in useEffect

  return (
    <>
      {toaster.status && (
        <NotificationToaster status={toaster.status} message={toaster.message} type={toaster.type} duration={toaster.duration} />
      )}
      </>
    );
};

export default App;
