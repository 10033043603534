import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../service/api.service';
import { CustomerNotification, Pagination } from '../../types';

export interface MarkAsRead{
  bn_id: number;
  patient_id: number
}

export const markAsRead = createAsyncThunk<number, MarkAsRead, { rejectValue: string }>(
  'notification/markAsRead',
  async (payload, { rejectWithValue }) => {
    try {
      
      if(!payload){
        return rejectWithValue('Please provide notification detail!');
      }      

      const response = await API({
        api: `profile/read_customer_notifications`,
        type: 'POST',
        body: payload
      });

      if (response.is_error) {
        return rejectWithValue(response.original_text);
      }

      return payload.bn_id;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to mark notification as read');
    }
  }
);

export interface NotificationRes {
    unread_count : number,
    count : number,
    data : CustomerNotification[]
}

interface NotificationInput extends Pagination{
    patient_id: number, 
    access_token?: string | null
} 

export const customerNotifications = createAsyncThunk<NotificationRes, NotificationInput, { rejectValue: string }>(
    'notification/all',
    async (payload, { rejectWithValue }) => {
      try {
        
        if(!payload){
          return rejectWithValue('Please provide notification detail!');
        }      
  
        const response = await API({
          api: `profile/customer_notifications?patient_id=${payload.patient_id}&limit=${payload.limit}&offset=${payload.offset}`,
          type: 'GET',
          body: payload,
          access_token: payload.access_token,
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }

        return response;
      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to mark notification as read');
      }
    }
  );
